import React, { useState } from "react";
import upload from "../../utils/upload";
import "./Register.scss";
import newRequest from "../../utils/newRequest";
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';
import axios from "axios";

function Register() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [audioFile, setAudioFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [user, setUser] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    img: "",
    aIimages: [],
    audioFile: "",
    eleven11voiceId: "",
    country: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    linkedin: "",
    Youtube: "",
    isSeller: false,
    desc: "",
    managerEmail: "",
    referrerCode:"",
  });
  const [showIframe, setShowIframe] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSeller = (e) => {
    setUser((prev) => {
      return { ...prev, isSeller: e.target.checked };
    });
  };

  const templateParams = {
    newUsername: user.username,
    newUseremail: user.email
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const aIimages = await Promise.all(
        [...files].map(async (file) => {
          const imagesUrl = await upload(file);
          return imagesUrl;
        })
      );

      let audioFileUrl = "";
      let eleven11voiceId = "";

      if (audioFile) {
        const audioFormData = new FormData();
        audioFormData.append("files", audioFile);
        audioFormData.append("name", user.username);
        audioFileUrl = await upload(audioFile);
        
        const elevenLabsResponse = await axios.post(
          "https://api.elevenlabs.io/v1/voices/add",
          audioFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              'xi-api-key': `sk_e5d3a65d2ca406714e1c7a6c1609a07566d30d2f241dd5d4`
            }
          }
        );
        eleven11voiceId = elevenLabsResponse.data.voice_id;
      }


      setUser((prev) => ({ ...prev, aIimages, audioFile: audioFileUrl, eleven11voiceId }));
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const profileUrl = await upload(file);

    try {
      await newRequest
        .post("/auth/register", {
          ...user,
          img: profileUrl,
        })
        .then((res) => {
          console.log("res => ", res);
          localStorage.setItem("userId", res.data.userId);
        });
      emailjs.send(
        "service_grcioxh",
        "template_50kh99z",
        templateParams,
        { publicKey: "SDCQ1BJyk-nqr804U" }
      );
      if (user.isSeller) {
        const response = await axios.post(
          "https://app.useanvil.com/api/v1/fill/hGuLXmX6urd4NSJcDdWn.pdf",
          {
            title: "Talent Contract",
            fontFamily: "Roboto",
            fontSize: 10,
            textColor: "#333333",
            useInteractiveFields: false,
            "data": {
              "sellerName": {
                "firstName": user.firstName,
               
                "lastName": user.lastName
              },
              "date": "today"
            }
          },
          
        );
        const pdfUrl = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        setIframeSrc(pdfUrl);
        setShowIframe(true);
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="register">
      <form onSubmit={handleSubmit}>
        <div className="left">
          <h1>Create a new account</h1>
          <label htmlFor="">Username</label>
          <input
            name="username"
            type="text"
            placeholder="johndoe"
            onChange={handleChange}
          />
          <label htmlFor="">First Name</label>
          <input
            name="firstName"
            type="text"
            placeholder="john"
            onChange={handleChange}
          />
          <label htmlFor="">Last Name</label>
          <input
            name="lastName"
            type="text"
            placeholder="john"
            onChange={handleChange}
          />
          <label htmlFor="">Address</label>
          <input
            name="address"
            type="text"
            placeholder="123 facefwd pl"
            onChange={handleChange}
          />
          <label htmlFor="">City</label>
          <input
            name="city"
            type="text"
            placeholder="New York"
            onChange={handleChange}
          />
          <label htmlFor="">State</label>
          <input
            name="state"
            type="text"
            placeholder="New York"
            onChange={handleChange}
          />
          <label htmlFor="">Zipcode</label>
          <input
            name="zipcode"
            type="text"
            placeholder="123 facefwd pl"
            onChange={handleChange}
          />
          <label htmlFor="">Country</label>
          <input
            name="country"
            type="text"
            placeholder="USA"
            onChange={handleChange}
          />
          <label htmlFor="">Email</label>
          <input
            name="email"
            type="email"
            placeholder="email"
            onChange={handleChange}
          />
          <label htmlFor="">Password</label>
          <input name="password" type="password" onChange={handleChange} />
          <label htmlFor="">Profile Picture</label>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <button type="submit">Register</button>
        </div>
        <div className="right">
          <div className="toggle">
            <label htmlFor="">Activate a talent account</label>
            <label className="switch">
              <input type="checkbox" onChange={handleSeller} />
              <span className="slider round"></span>
            </label>
          </div>
          <label htmlFor="">Phone Number</label>
          <input
            name="phone"
            type="text"
            placeholder="+1 234 567 89"
            onChange={handleChange}
          />
          <label htmlFor="">Description</label>
          <textarea
            placeholder="A short description of yourself"
            name="desc"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
          ></textarea>
          {user.isSeller && (
            <>
              <label htmlFor="">Manager Email</label>
              <input
                name="managerEmail"
                type="text"
                placeholder=""
                onChange={handleChange}
              /> 
              <label htmlFor="">Referrer Code</label>
              <input
                name="referrerCode"
                type="text"
                placeholder=""
                onChange={handleChange}
              />
              <label htmlFor="">Upload AI Images</label>
              <input
                type="file"
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
              <label htmlFor="">Upload Audio File</label>
              <input
                type="file"
                onChange={(e) => setAudioFile(e.target.files[0])}
              />
              <button type="button" onClick={handleUpload}>
                {uploading ? "Uploading" : "Upload"}
              </button>
              <h4>Add Social Media Links</h4>
              <label htmlFor="">Facebook</label>
              <input
                name="facebook"
                type="text"
                placeholder="Facebook profile link"
                onChange={handleChange}
              />
              <label htmlFor="">Instagram</label>
              <input
                name="instagram"
                type="text"
                placeholder="Instagram profile link"
                onChange={handleChange}
              />
              <label htmlFor="">TikTok</label>
              <input
                name="tiktok"
                type="text"
                placeholder="Tiktok account link"
                onChange={handleChange}
              />
              <label htmlFor="">LinkedIn</label>
              <input
                name="linkedin"
                type="text"
                placeholder="LinkedIn profile link"
                onChange={handleChange}
              />
              <label htmlFor="">Youtube</label>
              <input
                name="Youtube"
                type="text"
                placeholder="Youtube page link"
                onChange={handleChange}
              />
            </>
          )}
        </div>
      </form>
      
          
    </div>
      )}
      
  


export default Register;
