import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import newRequest from "../../../utils/newRequest";


export const Navigation = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const userId = currentUser?._id;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await newRequest.post("/auth/logout");
      localStorage.setItem("currentUser", null);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="/#page-top">
            FACEFWD.AI
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#features" className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href="/#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <Link className="link" to="/gigs">
                Talent
              </Link>
            </li>
            <li>
              <Link className="link" to="/itemdemo/6699dacaf153674371783b8d_itemtest">
                Try It
              </Link>
            </li>
            <li>
              <a href="/#contact" className="page-scroll">
                Contact
              </a>
            </li>
            {currentUser && (
              <li className="user" onClick={() => setOpen(!open)}>
                <img src={currentUser.img || "/img/noavatar.jpg"} alt="" />
                <span>{currentUser.username}</span>
                {open && (
                  <div className="options">
                    {currentUser.isSeller && (
                      <>
                        <Link className="link" to="/mygigs">
                          Gigs
                        </Link>
                        <Link className="link" to="/add">
                          Add New Gig
                        </Link>
                        <Link
                          className="link"
                          to="https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_PZ43ugZVZndc3wtUAtK8ZQc13MnwVSHs&scope=read_write&redirect_uri=https://facefwd.ai/stripe"
                        >
                          Payment Settings
                        </Link>
                      </>
                    )}
                    {currentUser.isAgency && (
                      <>
                        <Link className="link" to="/agencydashboard">
                          Agency Dashboard
                        </Link>
                        <Link
                          className="link"
                          to="https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_PZ43ugZVZndc3wtUAtK8ZQc13MnwVSHs&scope=read_write&redirect_uri=https://facefwd.ai/stripe"
                        >
                          Payment Settings
                        </Link>
                      </>
                    )}
                    <Link className="link" to="/orders">
                      Orders
                    </Link>
                    <Link className="link" to="/messages">
                      Messages
                    </Link>
                    <Link className="link" to={`/settings/${userId}`}>
                      Settings
                    </Link>
                    <Link className="link" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                )}
              </li>
            )}
            {!currentUser && (
              <>
               <a
                  href="/onboard"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Join
                </a>{" "}
               {/*} <li>
                <Link className="link" to="/onboard">
                    <button> Join </button>
                  </Link>
            </li>*/}
            <a
                  href="/login"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Sign In
                </a>{" "}
               {/*}  <li>
                  <Link className="link" to="/login">
                    <button>Sign In</button>
                  </Link>
                </li>*/}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
