import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.scss";

const ContactForm = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm('service_grcioxh', 'template_tahffva', form.current, {
        publicKey: 'SDCQ1BJyk-nqr804U',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          console.log('FAILED...', error.text);
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
      );
  };

  return (
    <div className="contact">
      <div className="contact-container">
        <h1>Contact Us</h1>
        <p>If you have any questions or need assistance, please reach out to us using the form below.</p>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="user_name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="user_email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required />
          </div>
          <div className="button">
            <input type="submit" value="Send Message" disabled={isSubmitting} />
          </div>
          {stateMessage && <p className="state-message">{stateMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;