import React, { useState, useEffect } from "react";
import { Header } from "../../components/tempComps/tempComps/header";
import { Features } from "../../components/tempComps/tempComps/features";
import { About } from "../../components/tempComps/tempComps/about";
//import { Services } from "../../components/tempComps/tempComps/services";
import { Contact } from "../../components/tempComps/tempComps/contact";
import JsonData from "../../assets/data.json";
import SmoothScroll from "smooth-scroll";
import "./HomePage.scss";
import { Navigation } from "../../components/tempComps/tempComps/navigation";
import { ForInfluencers } from "../../components/tempComps/tempComps/forInfluencers";
import { ForBrands } from "../../components/tempComps/tempComps/forBrands";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const HomePage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <ForInfluencers data={landingPageData.ForInfluencers} />
      <ForBrands data={landingPageData.ForBrands} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default HomePage;
