import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="top">
    
          <div className="item">
            
           <Link className="link" to='/privacy'><span>Privacy Policy</span></Link>
           <Link className="link" to='https://medium.com/@jonathan_47843/facefwd-ai-revolutionizing-ai-powered-content-creation-through-talent-licensing-9e5e419206fd'><span>Blog</span></Link>
           
          </div>
        </div>
        <hr />
        <div className="bottom">
          <div className="left">
            <h2></h2>
            <span>© facefwd llc 2024</span>
          </div>
          <div className="right">
            <div className="social">
            <Link className="link" to="https://www.facebook.com/profile.php?id=61552233960606">
              <img src="/img/facebook.png" alt="" />
              </Link>
              <Link className="link" to="https://www.linkedin.com/company/facefwd-ai/about/">
              <img src="/img/linkedin.png" alt="" />
              </Link>
              <Link className="link" to="https://www.instagram.com/facefwd.ai/">
              <img src="/img/instagram.png" alt="" />
              </Link>
            </div>
            
            <div className="link">
              <img src="/img/coin.png" alt="" />
              <span>USD</span>
            </div>
            <img src="/img/accessibility.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
