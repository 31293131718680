import React, { useState, useEffect } from "react";
import "./Gig.scss";
import { Slider } from "infinite-react-carousel";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import Reviews from "../../components/reviews/Reviews";
import getCurrentUser from "../../utils/getCurrentUser";
import { FaFacebook, FaInstagram, FaTiktok, FaLinkedin, FaYoutube, FaTwitter } from 'react-icons/fa';

function Gig() {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [shown, setShown] = useState(false);
  const [url, setUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { isLoading, error, data } = useQuery({
    queryKey: ["gig"],
    queryFn: () => newRequest.get(`/gigs/single/${id}`).then((res) => res.data),
  });

  const userId = data?.userId;

  const {
    isLoading: isLoadingUser,
    error: errorUser,
    data: dataUser,
  } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => newRequest.get(`/users/${userId}`).then((res) => res.data),
    enabled: !!userId,
  });

  const handleContact = async () => {
    const sellerId = data?.userId;
    const buyerId = currentUser?._id;
    const convoId = sellerId + buyerId;

    try {
      const res = await newRequest.get(`/conversations/single/${convoId}`);
      navigate(`/message/${res.data.id}`);
    } catch (err) {
      if (err.response.status === 404) {
        const res = await newRequest.post(`/conversations/`, {
          to: currentUser.seller ? buyerId : sellerId,
        });
        navigate(`/message/${res.data.id}`);
      }
    }
  };
 

  useEffect(() => {
    if (data && data.packages && data.packages.length > 0) {
      // Set the default package to the first one if none is selected
      setSelectedPackage(data.packages[0]);
    }
  }, [data]);

  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
  };

  useEffect(() => {
    const handleMessage = ({ origin, data: eventObject }) => {
      if (origin !== 'https://app.useanvil.com') return;
      console.log("Received message from Anvil:", eventObject); // Add logging here to debug
      if (eventObject && typeof eventObject === 'object') {
        if (eventObject.action === 'signerComplete') {
          setShown(false);
          navigate(`/pay/${id}`, { state: { packageName: selectedPackage?.name, packagePrice: selectedPackage?.price } });
        }
      }
    };
    
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [id, navigate, selectedPackage]);

  

  useEffect(() => {
    if (selectedPackage && dataUser && currentUser && data) {
      const buildURL = (path, dParam) => `${path}${encodeURIComponent(dParam)}`;
      
      const exampleData = {
        SellerAddress: {
          street1: dataUser.address,
          city: dataUser.city,
          state: dataUser.state,
          zip: dataUser.zipcode,
          country: dataUser.country,
        },
        SellerName: {
          firstName: dataUser.firstName,
          lastName: dataUser.lastName,
        },
        buyerName: {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        },
        catEx: JSON.stringify(data.features),
        customerProvisions: data.shortDesc,
        buyerEmail: currentUser.email,
        sellerEmail: dataUser.email,
        gigPrice: selectedPackage.price, // Passing the selected package price
        projDesc: selectedPackage.name,  // Passing the selected package name
        buyerUsAddress: {
          street1: currentUser.address,
          city: currentUser.city,
          state: currentUser.state,
          zip: currentUser.zipcode,
          country: currentUser.country,
        },
      };

      const dParam = JSON.stringify(exampleData);
      const path = "https://app.useanvil.com/form/facefwd-llc/ffwcontractform?d=";
      setUrl(buildURL(path, dParam));
    }
  }, [selectedPackage, dataUser, currentUser, data]);
 
  useEffect(() => {
    if (url) {
      console.log("Generated URL:", url);
    }
  }, [url]);
  const handleContinue = () => {
    if (currentUser && !currentUser.seller) {
      setShown(true);
    } else {
      setErrorMessage("You need to be logged in as a buyer to continue.");  // Set error message
    }
  };


  return (
    <div className="gig">
      {isLoading ? (
        "loading"
      ) : error ? (
        "Something went wrong!"
      ) : (
        <div className="container">
          <div className="left">
            <h1>{data.title}</h1>
            {isLoadingUser ? (
              "loading"
            ) : errorUser ? (
              "Something went wrong!"
            ) : (
              dataUser && (
                <div className="user">
                  <img
                    className="pp"
                    src={dataUser.img || "/img/noavatar.jpg"}
                    alt=""
                  />
                  <span>{dataUser.username}</span>
                  {!isNaN(data.totalStars / data.starNumber) && (
                    <div className="stars">
                      {Array(Math.round(data.totalStars / data.starNumber))
                        .fill()
                        .map((item, i) => (
                          <img src="/img/star.png" alt="" key={i} />
                        ))}
                      <span>{Math.round(data.totalStars / data.starNumber)}</span>
                    </div>
                  )}
                </div>
              )
            )}
            <Slider slidesToShow={1} arrowsScroll={1} className="slider">
              {data.images.map((img) => (
                <img key={img} src={img} alt="" />
              ))}
            </Slider>
            <h2>About the Talent</h2>
            <p>{data.desc}</p>
            {isLoadingUser ? (
              "loading"
            ) : errorUser ? (
              "Something went wrong!"
            ) : (
              dataUser && (
                <div className="seller">
                  <div className="user">
                    <img src={dataUser.img || "/img/noavatar.jpg"} alt="" />
                    <div className="info">
                      <span>{dataUser.username}</span>
                      {!isNaN(data.totalStars / data.starNumber) && (
                        <div className="stars">
                          {Array(Math.round(data.totalStars / data.starNumber))
                            .fill()
                            .map((item, i) => (
                              <img src="/img/star.png" alt="" key={i} />
                            ))}
                          <span>{Math.round(data.totalStars / data.starNumber)}</span>
                        </div>
                      )}
                      <button onClick={handleContact}>Contact Me</button>
                    </div>
                  </div>
                  <div className="box">
                    <div className="items">
                      <div className="item">
                        <span className="desc">{dataUser.city}, {dataUser.state} {dataUser.country}</span>
                      </div>
                      <div className="item">
                        <span className="title">
                          <a href={dataUser.facebook} target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={32} />
                          </a>
                        </span>
                        <span className="title">
                          <a href={dataUser.instagram} target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={32} color="#E4405F" />
                          </a>
                        </span>
                        <span className="title">
                          <a href={dataUser.tiktok} target="_blank" rel="noopener noreferrer">
                            <FaTiktok size={32} color="#010101" />
                          </a>
                        </span>
                        <span className="title">
                          <a href={dataUser.linkedin} target="_blank" rel="noopener noreferrer">
                            <FaLinkedin size={32} color="#0e76a8" />
                          </a>
                        </span>
                        <span className="title">
                          <a href={dataUser.Youtube} target="_blank" rel="noopener noreferrer">
                            <FaYoutube size={32} color="#FF0000" />
                          </a>
                        </span>
                        <span className="title">
                          <a href={dataUser.twitter} target="_blank" rel="noopener noreferrer">
                            <FaTwitter size={32} color="#1DA1F2" />
                          </a>
                        </span>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              )
            )}
            <Reviews gigId={id} />
          </div>
          <div className="right">
            <div className="price">
              <h3>{data.shortTitle}</h3>
              <h2>$ {selectedPackage ? selectedPackage.price : (data.packages && data.packages[0] ? data.packages[0].price :  data.packages[0].price)}</h2>
            </div>
            <p>{data.shortDesc}</p>
            <div className="features">
              {data.features.map((feature) => (
                <div className="item" key={feature}>
                  <img src="/img/dislike.png" alt="" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
            <div className="packages">
              {data.packages && data.packages.map((pkg, index) => (
                <div key={index} className={`packageItem ${selectedPackage === pkg ? "selected" : ""}`} onClick={() => handlePackageSelect(pkg)}>
                  <span>{pkg.name} - ${pkg.price}</span>
                </div>
              ))}
            </div>
            <button onClick={handleContinue}>Continue</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display the error message */}
            {shown && (
              <iframe
                src={url}
                title="contract"
                frameBorder="0"
                className="iframe"
              ></iframe>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Gig;
