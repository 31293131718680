import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import newRequest from "../../utils/newRequest";

function Stripe() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const customerId = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : JSON.parse(localStorage.getItem("currentUser"))._id;
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const updateStripeId = async () => {
    await newRequest.post("/auth/stripe/connect", {
      user_id: currentUser ? currentUser._id : customerId,
      stripe_id: code,
    });
  };

  const handleLogout = async () => {
    try {
      await newRequest.post("/auth/logout");
      localStorage.setItem("currentUser", null);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (code && customerId) {
      try {
        updateStripeId();
      } catch (err) {
        console.log(err);
      }
    } else {
      if (currentUser) handleLogout();
    }
  }, [code, customerId]);

  return (
    <div className="register">
      {code && customerId ? (
        currentUser ? (
          <p>Your stripe account is now connected.</p>
        ) : (
          <p>
            Your stripe account is now connected. Now you can login to your
            account
          </p>
        )
      ) : (
        <p>Stripe connect request declined</p>
      )}
    </div>
  );
}

export default Stripe;
