import React, { useState, useEffect } from "react";
import upload from "../../utils/upload";
import "./Settings.scss";
import newRequest from "../../utils/newRequest";
import { useNavigate } from "react-router-dom";

function Settings() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [uploading, setUploading] = useState(false);
  const [user, setUser] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    img: "",
    aIimages: [],
    country: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    linkedin: "",
    isSeller: false,
    desc: "",
    managerEmail: "",
    phone: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await newRequest.get(`/users/${currentUser._id}`);
        setUser(res.data);
      } catch (err) {
        console.error("Error fetching user data", err);
      }
    };
    fetchUserData();
  }, [currentUser._id]);

  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSeller = (e) => {
    setUser((prev) => ({ ...prev, isSeller: e.target.checked }));
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const aIimages = await Promise.all(
        [...files].map(async (file) => {
          const imagesUrl = await upload(file);
          return imagesUrl;
        })
      );
      setUser((prev) => ({ ...prev, aIimages }));
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let profileUrl = user.img;

    if (file) {
      profileUrl = await upload(file);
    }

    try {
      const updatedUser = {
        ...user,
        img: profileUrl,
      };

      await newRequest.put(`/users/${currentUser._id}`, updatedUser);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="settings">
      <form onSubmit={handleSubmit}>
        <div className="left">
          <h1>Update your account</h1>
          <label htmlFor="username">Username</label>
          <input
            name="username"
            type="text"
            placeholder="johndoe"
            value={user.username}
            onChange={handleChange}
          />
          <label htmlFor="firstName">First Name</label>
          <input
            name="firstName"
            type="text"
            placeholder="john"
            value={user.firstName}
            onChange={handleChange}
          />
          <label htmlFor="lastName">Last Name</label>
          <input
            name="lastName"
            type="text"
            placeholder="john"
            value={user.lastName}
            onChange={handleChange}
          />
          <label htmlFor="address">Address</label>
          <input
            name="address"
            type="text"
            placeholder="123 facefwd pl"
            value={user.address}
            onChange={handleChange}
          />
          <label htmlFor="city">City</label>
          <input
            name="city"
            type="text"
            placeholder="New York"
            value={user.city}
            onChange={handleChange}
          />
          <label htmlFor="state">State</label>
          <input
            name="state"
            type="text"
            placeholder="New York"
            value={user.state}
            onChange={handleChange}
          />
          <label htmlFor="zipcode">Zipcode</label>
          <input
            name="zipcode"
            type="text"
            placeholder="12345"
            value={user.zipcode}
            onChange={handleChange}
          />
          <label htmlFor="country">Country</label>
          <input
            name="country"
            type="text"
            placeholder="USA"
            value={user.country}
            onChange={handleChange}
          />
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="email"
            placeholder="email"
            value={user.email}
            onChange={handleChange}
          />
          <label htmlFor="password">Password</label>
          <input
            name="password"
            type="password"
            placeholder="******"
            value={user.password}
            onChange={handleChange}
          />
          <label htmlFor="img">Profile Picture</label>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <button type="submit">Update</button>
        </div>
        <div className="right">
          <div className="toggle">
            <label htmlFor="isSeller">Activate a talent account</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={user.isSeller}
                onChange={handleSeller}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <label htmlFor="phone">Phone Number</label>
          <input
            name="phone"
            type="text"
            placeholder="+1 234 567 89"
            value={user.phone}
            onChange={handleChange}
          />
          <label htmlFor="desc">Description</label>
          <textarea
            placeholder="A short description of yourself"
            name="desc"
            value={user.desc}
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
          ></textarea>
          {user.isSeller && (
            <>
              <label htmlFor="managerEmail">Manager Email</label>
              <input
                name="managerEmail"
                type="text"
                placeholder=""
                value={user.managerEmail}
                onChange={handleChange}
              />
              <label htmlFor="aIimages">Upload AI Images</label>
              <input
                type="file"
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
              <button type="button" onClick={handleUpload}>
                {uploading ? "Uploading" : "Upload"}
              </button>
              <h4>Add Social Media Links</h4>
              <label htmlFor="facebook">Facebook</label>
              <input
                name="facebook"
                type="text"
                placeholder="Facebook profile link"
                value={user.facebook}
                onChange={handleChange}
              />
              <label htmlFor="instagram">Instagram</label>
              <input
                name="instagram"
                type="text"
                placeholder="Instagram profile link"
                value={user.instagram}
                onChange={handleChange}
              />
              <label htmlFor="tiktok">TikTok</label>
              <input
                name="tiktok"
                type="text"
                placeholder="TikTok account link"
                value={user.tiktok}
                onChange={handleChange}
              />
              <label htmlFor="linkedin">LinkedIn</label>
              <input
                name="linkedin"
                type="text"
                placeholder="LinkedIn profile link"
                value={user.linkedin}
                onChange={handleChange}
              />
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default Settings;
