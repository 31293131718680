import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import newRequest from "../../utils/newRequest";
import "./Messages.scss";
import moment from "moment";

const Messages = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [userData, setUserData] = useState({});

  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery({
    queryKey: ["conversations"],
    queryFn: () =>
      newRequest.get(`/conversations`).then((res) => res.data),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (data && data.length > 0) {
        try {
          const userIds = Array.from(new Set(data.flatMap(c => [c.sellerId, c.buyerId])));
          const userResponses = await Promise.all(userIds.map(id => newRequest.get(`/users/${id}`)));
          const users = userResponses.reduce((acc, response) => {
            acc[response.data._id] = response.data;
            return acc;
          }, {});
          setUserData(users);
        } catch (err) {
          console.error("Error fetching user data", err);
        }
      }
    };

    fetchUserData();
  }, [data]);

  const mutation = useMutation({
    mutationFn: (id) => newRequest.put(`/conversations/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["conversations"]);
    },
  });

  const handleRead = (id) => {
    mutation.mutate(id);
  };

  if (isLoading) return "Loading...";
  if (error) return "Error";

  return (
    <div className="messages">
      <div className="container">
        <div className="title">
          <h1>Messages</h1>
        </div>
        <table>
          <thead>
            <tr>
              <th>{currentUser.isSeller ? "Buyer" : "Talent"}</th>
              <th>Last Message</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.map((c) => {
              const otherUserId = currentUser.isSeller ? c.buyerId : c.sellerId;
              const otherUser = userData[otherUserId];
              return (
                <tr
                  className={
                    ((currentUser.isSeller && !c.readBySeller) ||
                      (!currentUser.isSeller && !c.readByBuyer)) &&
                    "active"
                  }
                  key={c.id}
                >
                  <td>
                    <Link to={`/message/${c.id}`} className="link">
                      {otherUser?.username || "Loading..."}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/message/${c.id}`} className="link">
                      {c?.lastMessage?.substring(0, 100)}...
                    </Link>
                  </td>
                  <td>{moment(c.updatedAt).fromNow()}</td>
                  <td>
                    {((currentUser.isSeller && !c.readBySeller) ||
                      (!currentUser.isSeller && !c.readByBuyer)) && (
                      <button onClick={() => handleRead(c.id)}>
                        Mark as Read
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Messages;