import React, { useEffect, useState } from "react";
import "./Pay.scss";
import newRequest from "../../utils/newRequest";
import { useParams, useLocation } from "react-router-dom";
import CheckoutForm from "../../components/checkoutForm/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe('pk_live_51OZKeOL1HjGyOzT5inzcGKOjt1IocEW9FDuuASmcrrYUzb6hZxKp3Kidt2YS5yh3gU9se1mSTSH7D7Kt0WZN2lAG00XkRUVA3A');

const Pay = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [stripeFee, setStripeFee] = useState(0);

  const { id } = useParams();
  const location = useLocation();

  // Retrieve selected package details from the location state
  const { packageName, packagePrice } = location.state || {};

  
  useEffect(() => {
    const fetchPaymentIntent = async () => {
      try {
        // Use the selected package price for payment calculation
        const res = await newRequest.post(`/orders/create-payment-intent/${id}`, {
          packagePrice: packagePrice,
          packageName: packageName,
        });
        setClientSecret(res.data.clientSecret);

        const total = calculateTotalAmount(packagePrice);
        setTotalAmount(total.toFixed(2));
        setStripeFee((total - packagePrice).toFixed(2));
      } catch (err) {
        console.log(err);
      }
    };

    if (packagePrice) {
      fetchPaymentIntent();
    }
  }, [id, packagePrice]);

  const calculateTotalAmount = (price) => {
    const stripePercentageFee = 0.029;
    const fixedFlatFee = 0.29;
    return (price + fixedFlatFee) / (1 - stripePercentageFee);
  };

  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#695bc4',
    },
  };

  return (
    <div className="pay">
      <div className="container">
        <h2>Payment Details</h2>
        <p>Package: {packageName}</p>
        <p>Package Price: ${packagePrice}</p>
        <p>Processing Fee: ${stripeFee}</p>
        <p>Total Amount (including Stripe fees): ${totalAmount}</p>
        {clientSecret && (
          <Elements
            options={{ clientSecret, appearance }}
            stripe={stripePromise}
          >
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default Pay;
