import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import emailjs from '@emailjs/browser';
import './AgencyDash.scss';

const AgencyDashboard = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [sellersData, setSellersData] = useState({});
  const [buyersData, setBuyersData] = useState({});
  const [tab, setTab] = useState('orders');
  const navigate = useNavigate();

  const [agencyOrders, setAgencyOrders] = useState([]);
  const [talent, setTalent] = useState([]);
  const [gigs, setGigs] = useState({});

  useEffect(() => {
    if (!currentUser.isAgency) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  const { data, isLoading, error } = useQuery({
    queryKey: ["orders"],
    queryFn: () => newRequest.get(`/orders/agency`).then((res) => res.data),
    enabled: currentUser.isAgency,
  });
  
  const { data: talentData, isLoading: isLoadingTalent, error: errorTalent } = useQuery({
    queryKey: ["users"],
    queryFn: () => newRequest.get(`/users/agencySellers/${currentUser.agency}`).then((res) => res.data),
    enabled: currentUser.isAgency,
  });

  useEffect(() => {
    if (data) {
      const fetchSellersAndBuyersData = async () => {
        const sellers = await Promise.all(
          data.map(async (order) => {
            const seller = await newRequest.get(`/users/${order.sellerId}`);
            return { sellerId: order.sellerId, seller: seller.data, sellerName: seller.username };
          })
        );

        const buyers = await Promise.all(
          data.map(async (order) => {
            const buyer = await newRequest.get(`/users/${order.buyerId}`);
            return { buyerId: order.buyerId, buyer: buyer.data };
          })
        );

        const sellersMap = sellers.reduce((acc, curr) => {
          acc[curr.sellerId] = curr.seller;
          return acc;
        }, {});
        const buyersMap = buyers.reduce((acc, curr) => {
          acc[curr.buyerId] = curr.buyer;
          return acc;
        }, {});
        
        setSellersData(sellersMap);
        setBuyersData(buyersMap);
      };

      fetchSellersAndBuyersData();
      setAgencyOrders(data);
    }

    if (talentData) {
      setTalent(talentData);
      const fetchGigs = async () => {
        const gigsBySeller = await Promise.all(
          talentData.map(async (seller) => {
            const gigsResponse = await newRequest.get(`/gigs?userId=${seller._id}`);
            return {
              sellerId: seller._id,
              gigs: gigsResponse.data || [], // Ensure this is an array
              sellerName: seller.username || "Unknown",
            };
          })
        );
        const gigsMap = gigsBySeller.reduce((acc, curr) => {
          acc[curr.sellerId] = { gigs: curr.gigs, sellerName: curr.sellerName };
          return acc;
        }, {});
        setGigs(gigsMap);
      };
      
      fetchGigs();
    }
  }, [data, talentData]);

  const completeMutation = useMutation({
    mutationFn: (id) => newRequest.put(`/orders/${id}/complete`),
    onSuccess: () => {
      useQueryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
 

  const createReviewMutation = useMutation({
    mutationFn: (reviewData) => newRequest.post(`/creativereview`, reviewData),
    onSuccess: () => {
      useQueryClient.invalidateQueries({ queryKey: ["creativereview"] });
    },
  });

  const deleteGigMutation = useMutation({
    mutationFn: (gigId) => newRequest.delete(`/gigs/${gigId}`),
    onSuccess: () => {
      useQueryClient.invalidateQueries({ queryKey: ["gigs"] });
    },
  });

  const handleComplete = (id) => {
    completeMutation.mutate(id, {
      onSuccess: async (data) => {
        const order = data;
        const buyer = buyersData[order.buyerId];
        const seller = sellersData[order.sellerId];
  
        const templateParams = {
          to_email: currentUser.isSeller ? buyer.email : seller.email,
          from_email: currentUser.email,
          reviewUrl: `https://facefwd.ai/orders`,
          to_name: currentUser.isSeller ? buyer.username : seller.username,
          from_name: currentUser.username,
        };
  
        emailjs.send('service_grcioxh', 'template_a9pz586', templateParams, 'SDCQ1BJyk-nqr804U')
          .then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
              console.log('FAILED...', error);
            }
          );
      }
    });
  };

  const handleLaunchReview = async (order) => {
    try {
      await newRequest.get(`/creativereview/${order._id}`);
      navigate(`/creativereview/${order._id}`);
    } catch (err) {
      if (err.response.status === 404) {
        const reviewData = {
          orderId: order._id,
          title: order.title,
          status: order.status,
          sellerId: order.sellerId,
          buyerId: order.buyerId,
        };
        await createReviewMutation.mutateAsync(reviewData);
        navigate(`/creativereview/${order._id}`);
      }
    }
  };

  const handleAddGig = (sellerId) => {
    navigate("/add", { state: { sellerId } });
  };

  const handleDeleteGig = (gigId) => {
    deleteGigMutation.mutate(gigId);
  };

  const handleEditGig = (gigId, sellerId) => {
  navigate(`/editgig/${gigId}`, { state: { sellerId } });
};

  return (
    <div className="dashboard">
      <div className="tabs">
        <button className={tab === 'orders' ? 'active' : ''} onClick={() => setTab('orders')}>
          Orders
        </button>
        <button className={tab === 'talent' ? 'active' : ''} onClick={() => setTab('talent')}>
          Talent
        </button>
        <button className={tab === 'gigs' ? 'active' : ''} onClick={() => setTab('gigs')}>
          Gigs
        </button>
      </div>
      
      {tab === 'orders' && (
        isLoading ? (
          "Loading..."
        ) : error ? (
          "Error loading orders"
        ) : (
          <div className="container">
            <div className="title">
              <h1>Agency Dashboard - Orders</h1>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Seller Name</th>
                  <th>Buyer Name</th>
                  <th>Title</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Review</th>
                  <th>Complete</th>
                </tr>
              </thead>
              <tbody>
                {agencyOrders.map(order => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{sellersData[order.sellerId]?.username || "loading..."}</td>
                    <td>{buyersData[order.buyerId]?.username || "loading..."}</td>
                    <td>{order.title}</td>
                    <td>{order.price}</td>
                    <td>{order.status}</td>
                    <td> <button onClick={() => handleLaunchReview(order)}>
                                Review Creative
                              </button></td>
                    <td> <button onClick={() => handleComplete(order._id)}>
                              Complete Order
                            </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}
      
      {tab === 'talent' && (
        isLoadingTalent ? (
          "Loading talent data..."
        ) : errorTalent ? (
          "Error loading talent data"
        ) : (
          <div className="container">
            <div className="title">
              <h1>Agency Dashboard - Talent</h1>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {talent.map((seller) => (
                  <tr key={seller._id}>
                    <td>{seller.username}</td>
                    <td>{`${seller.firstName} ${seller.lastName}`}</td>
                    <td>{seller.email}</td>
                    <td>
                      <button onClick={() => handleAddGig(seller._id)}>
                        Add New Gig
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}

      {tab === 'gigs' && (
        <div className="container">
          <div className="title">
            <h1>Agency Dashboard - Gigs</h1>
          </div>
          {Object.keys(gigs).map((sellerId) => (
  <div key={sellerId}>
    <h1>{gigs[sellerId]?.sellerName || "Loading..."}</h1>
    <table>
      <thead>
        <tr>
          <th>Gig Title</th>
          <th>Talent</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(gigs[sellerId]?.gigs) && gigs[sellerId].gigs.length > 0 ? (
          gigs[sellerId].gigs.map((gig) => (
            <tr key={gig._id}>
              <td>
              <Link to={`/gig/${gig._id}`} className="link">{gig.title}</Link></td>
              <td>{gigs[sellerId]?.sellerName || "Loading..."}</td>
              <td>{gig.price}</td>
              <td>
                <button onClick={() => handleEditGig(gig._id, sellerId)}>Edit</button>
                <button onClick={() => handleDeleteGig(gig._id)}>Delete</button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">No gigs available for this seller.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AgencyDashboard;
