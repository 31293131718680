import React, { useReducer, useState, useEffect } from "react";
import "./EditGig.scss";  // You can reuse the Add.scss or create a new one if necessary
import { gigReducer, INITIAL_STATE } from "../../reducers/gigReducer";
import upload from "../../utils/upload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import getCurrentUser from "../../utils/getCurrentUser";

const EditGig = () => {
  const { id } = useParams();  // Extract gig ID from URL
  const [singleFile, setSingleFile] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const currentUser = getCurrentUser();
  const [state, dispatch] = useReducer(gigReducer, INITIAL_STATE);
  const [packageInput, setPackageInput] = useState({ name: "", price: "" });
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const sellerId = location.state?.sellerId || currentUser._id; // Use sellerId from state or fallback to current user's ID

  useEffect(() => {
    // Fetch the current gig data
    const fetchGigData = async () => {
      try {
        const res = await newRequest.get(`/gigs/single/${id}`);
        const gig = res.data;

        // Populate the form with the fetched gig data
        dispatch({ type: "CHANGE_INPUT", payload: { name: "title", value: gig.title } });
        dispatch({ type: "CHANGE_INPUT", payload: { name: "cat", value: gig.cat } });
        dispatch({ type: "CHANGE_INPUT", payload: { name: "desc", value: gig.desc } });
        dispatch({ type: "CHANGE_INPUT", payload: { name: "shortTitle", value: gig.shortTitle } });
        dispatch({ type: "CHANGE_INPUT", payload: { name: "shortDesc", value: gig.shortDesc } });
        dispatch({ type: "CHANGE_INPUT", payload: { name: "price", value: gig.price } });
        dispatch({ type: "ADD_IMAGES", payload: { cover: gig.cover, images: gig.images } });

        // Set packages if they exist
        if (gig.packages) {
          setPackages(gig.packages);
        }
      } catch (err) {
        console.log("Failed to load gig data:", err);
      }
    };

    fetchGigData();
  }, [id]);

  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handleFeature = (e) => {
    e.preventDefault();
    dispatch({
      type: "ADD_FEATURE",
      payload: e.target[0].value,
    });
    e.target[0].value = "";
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const cover = singleFile ? await upload(singleFile) : state.cover;
      const images = files.length > 0 ? await Promise.all(
        [...files].map(async (file) => {
          const url = await upload(file);
          return url;
        })
      ) : state.images;

      setUploading(false);
      return { cover, images };
    } catch (err) {
      console.log("Error uploading files:", err);
      setUploading(false);
      throw new Error("Failed to upload images");
    }
  };

  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    setPackageInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddPackage = () => {
    if (packageInput.name && packageInput.price) {
      setPackages((prev) => [...prev, packageInput]);
      setPackageInput({ name: "", price: "" });
    }
  };

  const handleRemovePackage = (index) => {
    setPackages(packages.filter((pkg, i) => i !== index));
  };

  const mutation = useMutation({
    mutationFn: (gig) => {
      return newRequest.put(`/gigs/agency-update/${id}`, gig);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["myGigs"]);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { cover, images } = await handleUpload();

      const gigData = {
        ...state,
        cover,
        images,
        packages, // Add packages to the gig data
        sellerId: sellerId || currentUser._id, // Ensure sellerId is correctly passed
      };
      console.log("gigData:", gigData);
      if (currentUser.isAgency) {
        // Use the agency-specific endpoint if the user is an agency
        mutation.mutate(gigData, {
          mutationFn: (data) => newRequest.put(`/gigs/agency-update/${id}`, data),
        });
        navigate("/agencydashboard");
      } else {
        // Use the regular endpoint if the user is a seller
        mutation.mutate(gigData, {
          mutationFn: (data) => newRequest.put(`/gigs/${id}`, data),
        });
      }
      navigate("/mygigs");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <div className="edit">
      <div className="container">
        <h1>Edit Gig</h1>
        <div className="sections">
          <div className="info">
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              value={state.title}
              placeholder="e.g. I will do something I'm really good at"
              onChange={handleChange}
            />
            <label htmlFor="">Category</label>
            <select name="cat" id="cat" value={state.cat} onChange={handleChange}>
              <option value="Celeb">Celeb</option>
              <option value="Fashion">Fashion</option>
              <option value="Travel">travel</option>
              <option value="Fitness">Fitness & wellness</option>
              <option value="Tech">Tech & Gaming</option>
              <option value="Family">Parenting & Family</option>
              <option value="Food and Cooking">Food and cooking</option>
              <option value="Business">Business & Entrepreneurship</option>
              <option value="Art">Art</option>
              <option value="Education">Education and Motivation</option>
              <option value="Environment">Environment & Sustainability</option>
            </select>

            <div className="images">
              <div className="imagesInputs">
                <label htmlFor="">Cover Image</label>
                <input
                  type="file"
                  onChange={(e) => setSingleFile(e.target.files[0])}
                />
                <label htmlFor="">Upload Sample Content</label>
                <input
                  type="file"
                  multiple
                  onChange={(e) => setFiles(e.target.files)}
                />
              </div>
            </div>
            <label htmlFor="">Description</label>
            <textarea
              name="desc"
              value={state.desc}
              placeholder="Brief descriptions to introduce your service to customers highlighting audience details and strengths"
              cols="0"
              rows="16"
              onChange={handleChange}
            ></textarea>
            <button onClick={handleSubmit}>Update</button>
          </div>
          <div className="details">
            <label htmlFor="">Additional Contract Terms</label>
            <textarea
              name="shortDesc"
              value={state.shortDesc}
              placeholder="Specific terms to be added to the licensing contract"
              cols="30"
              rows="10"
              onChange={handleChange}
            ></textarea>
            <label htmlFor="">Add Category Exclusions</label>
            <form action="" className="add" onSubmit={handleFeature}>
              <select name="catExlusion" id="catExlusion" onChange={handleChange}>
                <option value="Adult Content">Adult Content</option>
                <option value="Politics">Politics</option>
                <option value="Gambling">Gambling</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Hate Speech">Hate Speech</option>
                <option value="Drugs">Drugs</option>
                <option value="Violence">Violence</option>
              </select>
              <button type="submit">add</button>
            </form>
            <div className="addedFeatures">
              {state?.features?.map((f) => (
                <div className="item" key={f}>
                  <button
                    onClick={() =>
                      dispatch({ type: "REMOVE_FEATURE", payload: f })
                    }
                  >
                    {f}
                    <span> X</span>
                  </button>
                </div>
              ))}
            </div>

            <label htmlFor="">Packages</label>
            <div className="packages">
              {packages.map((pkg, index) => (
                <div key={index} className="packageItem">
                  <span>{pkg.name} - ${pkg.price}</span>
                  <button onClick={() => handleRemovePackage(index)}>Remove</button>
                </div>
              ))}
            </div>
            <div className="addPackage">
              <input
                type="text"
                name="name"
                value={packageInput.name}
                placeholder="Package Name"
                onChange={handlePackageChange}
              />
              <input
                type="number"
                name="price"
                value={packageInput.price}
                placeholder="Package Price"
                onChange={handlePackageChange}
              />
              <button type="button" onClick={handleAddPackage}>Add Package</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGig;
