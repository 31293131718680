import React, { useEffect, useRef, useState } from "react";
import "./Gigs.scss";
import GigCard from "../../components/gigCard/GigCard";
import { useQuery } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import { useLocation, useNavigate } from "react-router-dom";

function Gigs() {
  const [sort, setSort] = useState("sales");
  const [open, setOpen] = useState(false);
  const minRef = useRef();
  const maxRef = useRef();
  const categoryRef = useRef();
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const cat = params.get("cat");

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["gigs", cat, sort],
    queryFn: () => {
      let url = `/gigs?sort=${sort}`;
      if (cat) {
        url += `&cat=${cat}`;
      }
      return newRequest.get(url).then((res) => res.data);
    },
  });

  const reSort = (type) => {
    setSort(type);
    setOpen(false);
  };

  useEffect(() => {
    refetch();
  }, [sort, cat]);

  const apply = () => {
    const selectedCategory = categoryRef.current.value;
    if (selectedCategory) {
      navigate(`?cat=${selectedCategory}`);
    } else {
      navigate(`?`);
    }
  };

  return (
    <div className="gigs">
      <div className="container">
        <div className="menu">
          <div className="left">
            <span>Budget</span>
            <input ref={minRef} type="number" placeholder="min" />
            <input ref={maxRef} type="number" placeholder="max" />
            <span>Category</span>
            <select name="cat" ref={categoryRef}>
              <option value="">All</option>
              <option value="Celeb">Celeb</option>
              <option value="Fashion">Fashion</option>
              <option value="Travel">Travel</option>
              <option value="Fitness">Fitness & Wellness</option>
              <option value="Tech">Tech & Gaming</option>
              <option value="Family">Parenting & Family</option>
              <option value="Food and Cooking">Food and Cooking</option>
              <option value="Business">Business & Entrepreneurship</option>
              <option value="Art">Art</option>
              <option value="Education">Education and Motivation</option>
              <option value="Environment">Environment & Sustainability</option>
            </select>
            <button onClick={apply}>Apply</button>
          </div>
          <div className="right">
            <span className="sortBy">Sort by</span>
            <span className="sortType">
              {sort === "sales" ? "Best Selling" : "Newest"}
            </span>
            <img src="./img/down.png" alt="" onClick={() => setOpen(!open)} />
            {open && (
              <div className="rightMenu">
                {sort === "sales" ? (
                  <span onClick={() => reSort("createdAt")}>Newest</span>
                ) : (
                  <span onClick={() => reSort("sales")}>Best Selling</span>
                )}
                <span onClick={() => reSort("sales")}>Popular</span>
              </div>
            )}
          </div>
        </div>
        <div className="cards">
          {isLoading
            ? "loading"
            : error
            ? "Something went wrong!"
            : data && data.length > 0
            ? data.map((gig) => <GigCard key={gig._id} item={gig} />)
            : "No gigs found"}
        </div>
      </div>
    </div>
  );
}

export default Gigs;
