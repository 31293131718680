import React from 'react';
import './About.scss';

const About = () => {
  return (
    <div className="about">
      <div className="about-container">
        <h1>Welcome to facefwd.ai - Where AI Meets Talent</h1>
        <p>
          At facefwd.ai, we're passionate about pushing the boundaries of creativity and technology. We believe that every face tells a story, and every story deserves to be told in innovative ways. That's why we've created a unique online marketplace where actors and creators can connect to bring AI-generated content to life.
        </p>
        
        <h2>Our Mission</h2>
        <p>
          Our mission is simple: to revolutionize the way content is created by harnessing the power of artificial intelligence and the talent of real individuals. We aim to provide a platform where actors can lend their likeness to be transformed into diverse digital experiences, from virtual characters in video games to virtual influencers on social media.
        </p>

        <h2>How It Works</h2>
        <p>
          Using our platform is straightforward. Actors simply create a profile showcasing their unique look and talent. Creators, whether they're game developers, filmmakers, or advertisers, browse through profiles to find the perfect match for their project. Once a match is made, our platform facilitates the licensing process, ensuring that both parties are protected and compensated fairly.
        </p>

        <h2>Why Choose Us</h2>
        <ul>
          <li><strong>Diverse Talent Pool:</strong> Our platform boasts a diverse array of actors from around the world, ensuring that creators can find the perfect fit for their project, no matter how unique.</li>
          <li><strong>Streamlined Process:</strong> Say goodbye to endless auditions and negotiations. Our platform simplifies the entire licensing process, saving both time and hassle.</li>
          <li><strong>Fair Compensation:</strong> We believe in fair compensation for talent. Our platform ensures that actors are properly compensated for the use of their likeness, empowering them to earn from their craft.</li>
          <li><strong>Cutting-Edge AI Technology:</strong> Partnering with leading AI experts, we leverage the latest advancements in artificial intelligence to create stunning digital experiences that captivate audiences.</li>
        </ul>

        <h2>Join Us Today</h2>
        <p>
          Whether you're an actor looking to lend your likeness to groundbreaking projects or a creator searching for the perfect face to bring your vision to life, facefwd.ai is the place for you. Join our community today and be part of the future of content creation!
        </p>
      </div>
    </div>
  );
};

export default About;