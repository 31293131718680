import React, { useReducer, useState, useEffect } from "react";
import "./Add.scss";
import { gigReducer, INITIAL_STATE } from "../../reducers/gigReducer";
import upload from "../../utils/upload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import { useNavigate, useLocation } from "react-router-dom";

const Add = () => {
  const [singleFile, setSingleFile] = useState(undefined);
  const [files, setFiles] = useState();
  const [uploading, setUploading] = useState(false);
  const location = useLocation();
  const sellerId = location.state?.sellerId;

  const [state, dispatch] = useReducer(gigReducer, INITIAL_STATE);
  const [packageInput, setPackageInput] = useState({ name: "", price: "" });
  const [packages, setPackages] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  useEffect(() => {
    if (sellerId) {
      dispatch({ type: "CHANGE_INPUT", payload: { name: "userId", value: sellerId } });
    }
  }, [sellerId]);

  useEffect(() => {
// Add the current user's agency to the state
    if (currentUser && currentUser.agency) {
      dispatch({
        type: "CHANGE_INPUT",
        payload: { name: "agency", value: currentUser.agency },
      });
    }
  }, [currentUser]);

  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    setPackageInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddPackage = () => {
    if (packageInput.name && packageInput.price) {
      setPackages((prev) => [...prev, packageInput]);
      setPackageInput({ name: "", price: "" });
    }
  };
const handleFeature = (e) => {
    e.preventDefault();
    dispatch({
      type: "ADD_FEATURE",
      payload: e.target[0].value,
    });
    e.target[0].value = "";
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const cover = await upload(singleFile);
      const images = await Promise.all(
        [...files].map(async (file) => {
          const url = await upload(file);
          return url;
        })
      );
      setUploading(false);
      return { cover, images };  // Return the URLs instead of dispatching here
    } catch (err) {
      console.log(err);
      setUploading(false);
      throw new Error('Failed to upload images');
    }
  };

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (gig) => {
      if (sellerId) {
// If sellerId is present, call the API for agencies to create a gig for a seller
        return newRequest.post("/gigs/agency-create", gig);
      } else {
// Otherwise, use the regular endpoint
        return newRequest.post("/gigs", gig);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["myGigs"]);
    },
  });
useEffect(() => {
    if (state.cover && state.images.length > 0) {
      console.log("Images and cover are set:", state.cover, state.images);
    }
  }, [state.cover, state.images]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { cover, images } = await handleUpload();

      // Now, dispatch the URLs to the state
      dispatch({ type: "ADD_IMAGES", payload: { cover, images } });

// Ensuring state update before proceeding
      const gigData = {
        ...state,
        cover,
        images,
        packages,
        sellerId: sellerId || currentUser._id,
      };

      console.log(gigData); // Verify that gigData contains correct URLs

      mutation.mutate(gigData);
      navigate("/mygigs");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <div className="add">
      <div className="container">
        <h1>Add New Gig</h1>
        <div className="sections">
          <div className="info">
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              placeholder="e.g. I will do something I'm really good at"
              onChange={handleChange}
            />
            <label htmlFor="">Category</label>
            <select name="cat" id="cat" onChange={handleChange}>
              <option value="Celeb">Celeb</option>
              <option value="Fashion">Fashion</option>
              <option value="Travel">Travel</option>
              <option value="Fitness">Fitness & Wellness</option>
              <option value="Tech">Tech & Gaming</option>
              <option value="Family">Parenting & Family</option>
              <option value="Food and Cooking">Food and Cooking</option>
              <option value="Business">Business & Entrepreneurship</option>
              <option value="Art">Art</option>
              <option value="Education">Education and Motivation</option>
              <option value="Environment">Environment & Sustainability</option>
            </select>

            <div className="images">
              <div className="imagesInputs">
                <label htmlFor="">Cover Image</label>
                <input
                  type="file"
                  onChange={(e) => setSingleFile(e.target.files[0])}
                />
                <label htmlFor="">Upload Sample Content</label>
                <input
                  type="file"
                  multiple
                  onChange={(e) => setFiles(e.target.files)}
                />
              </div>
            </div>
            <label htmlFor="">Description</label>
            <textarea
              name="desc"
              placeholder="Brief descriptions to introduce yourself to customers highlighting audience details and strengths"
              cols="0"
              rows="16"
              onChange={handleChange}
            ></textarea>

            <label htmlFor="">Add Packages</label>
            <input
              type="text"
              name="name"
              value={packageInput.name}
              placeholder="5 Images and 1 video"
              onChange={handlePackageChange}
            />
            <input
              type="number"
              name="price"
              value={packageInput.price}
              placeholder="Price"
              onChange={handlePackageChange}
            />
            <button type="button" onClick={handleAddPackage}>Add Package</button>

            <div className="addedPackages">
              {packages.map((pkg, index) => (
                <div className="packageItem" key={index}>
                  <span>{pkg.name} - ${pkg.price}</span>
                </div>
              ))}
            </div>

            <button onClick={handleSubmit}>Create</button>
          </div>
          <div className="details">
           {/* <label htmlFor="">Service Title</label>
            <input
              type="text"
              name="shortTitle"
              placeholder="Fitness influencer with 20k follower universe"
              onChange={handleChange}
              />*/}
            <label htmlFor="">Additional Contract Terms</label>
            <textarea
              name="shortDesc"
              onChange={handleChange}
              placeholder="Specific terms to be added to the licensing contract"
              cols="30"
              rows="10"
            ></textarea>

            <label htmlFor="">Add Category Exclusions</label>
            <form className="add" onSubmit={handleFeature}>
              <select name="catExlusion" id="catExlusion" onChange={handleChange}>
                <option value="Adult Content">Adult Content</option>
                <option value="Politics">Politics</option>
                <option value="Gambling">Gambling</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Hate Speech">Hate Speech</option>
                <option value="Drugs">Drugs</option>
                <option value="Violence">Violence</option>
              </select>
              <button type="submit">Add</button>
            </form>

            <div className="addedFeatures">
              {state?.features?.map((f) => (
                <div className="item" key={f}>
                  <button onClick={() => dispatch({ type: "REMOVE_FEATURE", payload: f })}>
                    {f} <span> X</span>
                  </button>
                </div>
              ))}
            </div>

            {/*<label htmlFor="">Price</label>
            <input type="number" onChange={handleChange} name="price" />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
