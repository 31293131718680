import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import newRequest from "../../utils/newRequest";
import "./Message.scss";
import emailjs from '@emailjs/browser';
import upload from "../../utils/upload";

const Message = () => {
  const { id } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const messageRef = useRef();
  const fileRef = useRef();
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = useState(false);

  const { isLoading, error, data } = useQuery({
    queryKey: ["messages"],
    queryFn: () =>
      newRequest.get(`/messages/${id}`).then((res) => res.data),
  });
  const { isLoading: isLoadingConversation, error: errorConversation, data: conversation } = useQuery({
    queryKey: ["conversations"],
    queryFn: () => newRequest.get(`/conversations/single/${id}`).then((res) => res.data),
  });
  const otherUserId = currentUser.seller
    ? conversation?.buyerId
    : conversation?.sellerId;

  const { isLoading: isLoadingOtherUser, error: errorOtherUser, data: otherUser } = useQuery({
    queryKey: ["user", otherUserId],
    queryFn: () => newRequest.get(`/users/${otherUserId}`).then((res) => res.data),
    enabled: !!otherUserId,
  });

  const mutation = useMutation({
    mutationFn: (message) => newRequest.post(`/messages`, message),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["messages"]);

      const templateParams = {
        to_email: otherUser.email,
        from_email: currentUser.email,
        text_message: variables.desc,
        to_name: otherUser.username,
        from_name: currentUser.username,
      };

      emailjs.send('service_grcioxh', 'template_lx6j9il', templateParams, {
        publicKey: 'SDCQ1BJyk-nqr804U',
      }).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const desc = messageRef.current.value;
    const file = fileRef.current.files[0];

    if (!otherUser) {
      console.error("Other user data not found or data is empty.");
      return;
    }

    const sellerId = conversation.sellerId;
    const buyerId = conversation.buyerId;

    let fileUrl = '';
    if (file) {
      setIsUploading(true);
      fileUrl = await upload(file);
      setIsUploading(false);
    }

    const message = {
      conversationId: id,
      desc: desc,
      fileUrl: fileUrl || null,
      sellerId: sellerId,
      buyerId: buyerId,
    };

    mutation.mutate(message);
    messageRef.current.value = "";
    fileRef.current.value = null;
  };

  const renderContent = (message) => {
    const text = message.desc;
    const fileUrl = message.fileUrl;

    return (
      <div>
        <p>{text}</p>
        {fileUrl && (
          fileUrl.match(/\.(jpeg|jpg|gif|png)$/) ? (
            <img src={fileUrl} alt="uploaded content" style={{ maxWidth: '200px', maxHeight: '200px' }} />
          ) : (
            <video controls style={{ maxWidth: '200px', maxHeight: '200px' }}>
              <source src={fileUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )
        )}
      </div>
    );
  };

  if (isLoading || isLoadingConversation || isLoadingOtherUser) {
    return "loading";
  }

  if (error || errorConversation || errorOtherUser) {
    return "error";
  }

  return (
    <div className="message">
      <div className="container">
        <span className="breadcrumbs">
          <Link to="/messages">Messages</Link>
        </span>
        <div className="messages">
          {data && data.map((m) => (
            <div
              className={m.userId === currentUser._id ? "owner item" : "item"}
              key={m._id}
            >
              <img
                src={
                  m.userId === currentUser._id
                    ? currentUser.img || "/img/noavatar.jpg"
                    : otherUser?.img || "/img/noavatar.jpg"
                }
                alt=""
              />
              {renderContent(m)}
            </div>
          ))}
        </div>
        <hr />
        <form className="write" onSubmit={handleSubmit}>
          <textarea
            type="text"
            ref={messageRef}
            placeholder="Write a message"
          />
          <input type="file" ref={fileRef} />
          <button type="submit" disabled={isUploading}>
            {isUploading ? "Uploading..." : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Message;